@import "@/assets/scss/var.scss";
.result {
    background: #f7f8fa;
    overflow: auto;
    height: 100vh;
    .step {
        padding: 0;
        margin-bottom: $offset-v;
        margin-top: $offset-v;
        background: #fff;
    }
    .icon {
        width: 8em;
        height: 8em;
        margin: 36px;
    }
    &__content {
    }
    &-btn-grp {
        margin-top: $offset-v;
    }
    &-block {
        padding: 20px 30px;
        margin-bottom: $offset-v;
        margin-top: $offset-v;
        background: #fff;

        .download-app {
            text-align: center;
            width: 480px;
            margin: auto;
            img {
                max-width: 100%;
            }
        }
        &__status {
            padding: 30px 36px;
            display: flex;
            flex-direction: row;
            justify-content: stretch;
            align-items: flex-start;
        }
        &__info {
            flex: 1;
            .btn-grp {
                > span {
                    padding: 8px 24px;
                    display: inline-block;
                    text-align: center;
                    font-size: 14px;
                    line-height: 1.5;
                    border: 1px solid transparent;
                    background: transparent;
                    cursor: pointer;
                    background: #fff;
                    border-color: $border-primary;
                    color: $color-primary;
                    width: auto;
                    height: 100%;
                    margin-top: 10px;
                }
                > span:first-child {
                    margin-right: 10px;
                }
            }
            &-desc {
                &__text {
                    font-weight: bold;
                    font-size: 24px;
                    color: $color-primary;
                }
            }
            &-msg {
                font-size: 1.55em;
                margin-top: 20px;
                margin-bottom: 20px;
            }
            &-msg ~ * {
                margin-bottom: 10px;
            }
        }
    }
    .order-detail {
        list-style: none;
        margin: 0;
        padding: 0;
        display: table;
        width: 100%;
        font-size: 14px;
        color: #999;
        &__label {
            width: 140px;
        }
        > li {
            margin-bottom: 5px;
        }
        li > div {
            display: table-cell;
        }
    }
    .addition {
        background: #f7f8fa;
        padding: 0;
        &-option {
            margin: 15px 0 10px 0;
            .el-checkbox-group /deep/ {
                font-size: 0;
                display: flex;
                flex-wrap: wrap;
                .el-checkbox__label {
                    padding-left: 0px;
                }
                .el-checkbox {
                    margin-right: 0;
                    text-align: center;
                }
                .el-checkbox.is-bordered {
                    width: 282px;
                    height: auto;
                    margin-left: 5px;
                    margin-right: 5px;
                    background: #fff;
                    position: relative;
                    margin-bottom: 10px;
                }
                // .el-checkbox__label .el-image {
                //     margin-right: 5px;
                // }
                .el-checkbox.is-bordered.el-checkbox {
                    padding: 8px 10px 8px 10px;
                }
                .el-checkbox__label {
                    width: 100%;
                }
                .el-checkbox.is-bordered.is-checked {
                    border-color: $border-primary;
                }
                .el-checkbox__input + .el-checkbox__label::after {
                    content: "";
                    display: block;
                    width: 0;
                    height: 0;
                    border: 15px solid #dcdfe6;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
                .el-checkbox__input + .el-checkbox__label::before {
                    content: "";
                    display: block;
                    width: 16px;
                    height: 7px;
                    border-left: 1px solid #fff;
                    border-bottom: 1px solid #fff;
                    transform: rotate(-45deg);
                    position: absolute;
                    right: 6px;
                    bottom: 13px;
                    z-index: 1;
                }
                .el-checkbox__input.is-checked + .el-checkbox__label {
                    color: initial;
                }
                .el-checkbox.is-bordered.el-checkbox .el-checkbox__inner {
                    opacity: 0;
                    outline: none;
                    position: absolute;
                    margin: 0;
                    z-index: -1;
                    height: 0px;
                    width: 0px;
                }
                .el-checkbox__input.is-checked + .el-checkbox__label::before {
                    content: "";
                    display: block;
                    width: 16px;
                    height: 7px;
                    border-left: 1px solid #fff;
                    border-bottom: 1px solid #fff;
                    transform: rotate(-45deg);
                    position: absolute;
                    right: 6px;
                    bottom: 13px;
                    z-index: 1;
                }
                .el-checkbox__input.is-checked + .el-checkbox__label::after {
                    content: "";
                    display: block;
                    width: 0;
                    height: 0;
                    /* border-bottom: 20px solid $border-primary; */
                    /* border-left: 25px solid transparent; */
                    border: 15px solid $border-primary;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }

            &__top {
                .el-image {
                    width: 180px;
                    height: 180px;
                    /* margin: 0 auto; */
                    /* text-align: center; */
                    margin: 20px;
                }

                &-name {
                    height: 40px;
                    font-size: 16px;
                    // .t {
                    //     height: 100%;
                    //     white-space: normal;
                    //     word-break: break-all;
                    // }
                }
            }
            &__bottom {
                &____desc {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &-sm {
                        color: $txt-color-grey;
                        display: inline-block;
                        /* width: 100%; */
                        max-width: 75%;
                    }
                    &-link {
                        margin: 0 5px;
                        font-size: 0.85em;
                        color: $color-error;
                    }
                }
                &-price {
                    font-size: 16px;
                    color: $color-error;
                    margin-left: 5px;
                }
                &-origPrice {
                    text-decoration: line-through;
                    color: $txt-color-grey;
                }
            }
            &-tag {
                background: $btn-color-primary;
                min-width: 20px;
                position: absolute;
                color: #fff;
                padding: 3px 8px;
                top: 20px;
                border-radius: 0px 20px 20px 0;
                font-size: 14px;
                left: 0;
                z-index: 1;
            }
        }
        &-btn-grp {
            text-align: right;
        }
    }
    ul, li {
        margin: 0;
        padding: 0;
        list-style: none;
    }
}
